import React, { useContext, useState } from "react"
import { Box, Button, Text } from "grommet"
import { CalculatorContext } from "../../context/CalculatorContext"

export default function NavigationButtons({
  value,
  validate,
  process,
  previousStep,
  nextStep,
  nextStepHandler,
}) {
  const calculatorContext = useContext(CalculatorContext)
  const [status, setStatus] = useState()

  const doPreviousStep = () => {
    calculatorContext.updateData(value)
    previousStep()
  }

  const doNextStep = async () => {
    let result = {
      valid: true,
    }

    if (validate) {
      result = validate(value)
    }

    if (result && process) {
      // data is valid and we have a process handler
      console.log("processing", value)
      const valid = await process(value)

      result = {
        valid,
        message: !valid ? "Unable to process the provided input" : undefined,
      }
    }

    if (result.valid) {
      calculatorContext.updateData(value)

      if (nextStepHandler) nextStepHandler()
      else nextStep()
    } else {
      setStatus(result.message)
    }
  }

  return (
    <Box pad="small">
      <Box id="buttons" flex>
        <Box direction="row" justify="center" gap="medium" align="end" flex>
          {previousStep && (
            <Button
              label="Previous"
              onClick={doPreviousStep}
              style={{ display: "block", width: "90%" }}
            />
          )}
          {nextStep && (
            <Button
              label="Next"
              onClick={doNextStep}
              style={{ display: "block", width: "90%" }}
              primary
            />
          )}
        </Box>
      </Box>
      {status && (
        <Box pad="small" align="end">
          <Text color="brand">{status}</Text>
        </Box>
      )}
    </Box>
  )
}
