import React, { useContext, useEffect, useState } from "react"

import { Box, Heading, Text, Form, FormField, TextInput } from "grommet"
import ScreenLayout from "../ScreenLayout"

import { CalculatorContext } from "../../../context/CalculatorContext"

import inputs from "../data/inputs.json"

const servicePrefix = "services_aws_"
const costPrefix = "aws_cost_"

export default function Screen({ previousStep, nextStep }) {
  const { data, getCurrencySymbol } = useContext(CalculatorContext)
  const [value, setValue] = useState({})

  useEffect(() => {
    let newValue = {}

    for (let v of Object.keys(inputs.awsServices)) {
      if (data[servicePrefix + v]) {
        newValue = { ...value, [costPrefix + v]: "" }
      }
    }

    setValue(newValue)
  }, [data])

  let list = Object.keys(data)
    .filter(r => r.startsWith(servicePrefix))
    .map(r => r.replace(servicePrefix, ""))

  const validate = data => {
    let valid = true

    for (let k of Object.keys(value)) {
      if (!data[k] || isNaN(data[k]) || data[k] === 0) {
        valid = false
        break
      }
    }

    return {
      valid,
      message: !valid && "cost must be greater than 0 for all resources",
    }
  }

  return (
    <ScreenLayout
      value={value}
      validate={validate}
      previousStep={previousStep}
      nextStep={nextStep}
    >
      <Box pad="medium" gap="small">
        <Heading level="3">
          How much are you paying for each of your AWS Services?
        </Heading>
        <Text color="dark-5">
          Provide the combined cost per service, per month.
        </Text>
        <Box pad="medium">
          <Form
            value={value}
            onChange={nextValue => {
              setValue(nextValue)
            }}
          >
            {list.map(r => (
              <FormField key={r} label={inputs.awsServices[r]} width="small">
                <TextInput
                  name={costPrefix + r}
                  placeholder={getCurrencySymbol()}
                />
              </FormField>
            ))}
          </Form>
        </Box>
      </Box>
    </ScreenLayout>
  )
}
