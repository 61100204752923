import React, { useState } from "react"

import calculate from "./calculate"

const CalculatorContext = React.createContext()

const CalculatorProvider = props => {
  const [data, setData] = useState({})
  const [currency, setCurrency] = useState("")

  const updateData = newData => {
    setData({ ...data, ...newData })
  }

  const getCurrencySymbol = () => {
    switch (currency) {
      case "USD":
        return "$"
      case "GBP":
        return "£"
      case "EUR":
        return "€"
      default:
        return "?"
    }
  }

  return (
    <CalculatorContext.Provider
      value={{
        data,
        updateData,
        calculate,
        currency,
        setCurrency,
        getCurrencySymbol,
      }}
    >
      {props.children}
    </CalculatorContext.Provider>
  )
}

export { CalculatorContext, CalculatorProvider }
