import React from "react"
import PropTypes from "prop-types"
import { Box } from "grommet"

const Section = ({ children, width, height, background, pad, ...props }) => (
  <Box align="center" background={background} pad={pad} {...props}>
    <Box width={width} height={height}>
      {children}
    </Box>
  </Box>
)

Section.propTypes = {
  ...Box.propTypes,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
}

Section.defaultProps = {
  width: "xlarge",
  height: "unset",
  pad: "large",
}

export default Section
