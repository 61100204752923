import React from "react";

import { Box, Text, ResponsiveContext } from "grommet";

export default function LabelledValue({
  label,
  direction,
  gap,
  labelSize,
  valueSize,
  helpSize,
  help,
  border,
  metric,
  pad,
  children,
}) {
  const size = React.useContext(ResponsiveContext);

  return (
    <Box
      direction={size === "small" ? "column" : "row"}
      gap={gap}
      border={border}
      pad={pad}
      align={size === "small" ? "center" : "start"}
      background="light-1"
    >
      <Box width={size !== "small" ? "small" : ""}>
        <Text size={labelSize} color="dark-5">
          {label}
        </Text>
      </Box>
      <Box align={size === "small" ? "center" : "start"}>
        <Text size={valueSize}>{children}</Text>
        {metric && <Text color="dark-5">{metric}</Text>}
      </Box>

      {help && (
        <Box justify="end" flex>
          <Text color="dark-3" size={helpSize}>
            {help}
          </Text>
        </Box>
      )}
    </Box>
  );
}

LabelledValue.defaultProps = {
  pad: "small",
  gap: "medium",
  labelSize: "medium",
  valueSize: "xxlarge",
  helpSize: "small",
};
