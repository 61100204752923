import React, { useContext } from "react"

import { Box, Heading } from "grommet"
import ScreenLayout from "../ScreenLayout"
import { InlineWidget } from "react-calendly"

import { CalculatorContext } from "../../../context/CalculatorContext"

export default function Screen({ isActive, previousStep, nextStep }) {
  const { data } = useContext(CalculatorContext)
  return (
    <ScreenLayout>
      <Box pad="medium" gap="small">
        <Heading level="3">Book a call with a Cloud consultant</Heading>
        {/* <Text color="dark-5">Choose all that apply.</Text> */}
        <Box flex>
          {isActive && (
            <InlineWidget
              pageSettings={{
                backgroundColor: "ffffff",
                hideEventTypeDetails: true,
                hideLandingPageDetails: true,
                primaryColor: "DB372A",
                // textColor: "4d5055",
              }}
              prefill={{
                // customAnswers: {
                //   a1: "a1",
                //   a10: "a10",
                //   a2: "a2",
                //   a3: "a3",
                //   a4: "a4",
                //   a5: "a5",
                //   a6: "a6",
                //   a7: "a7",
                //   a8: "a8",
                //   a9: "a9",
                // },
                email: data.email,
                // firstName: "Jon",
                // lastName: "Snow",
                name: data.name,
              }}
              styles={{
                height: "700px",
              }}
              url="https://calendly.com/furnace-sales/cloud-consult-30min"
              utm={{
                utmCampaign: "Cloud Calculator",
                utmContent: "",
                utmMedium: "Ad",
                utmSource: "Facebook",
                // utmTerm: "Spring",
              }}
            />
          )}
        </Box>
      </Box>
    </ScreenLayout>
  )
}
