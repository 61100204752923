import React, { useContext, useState } from "react"

import {
  Box,
  Heading,
  Text,
  Button,
  Image,
  Select,
  ResponsiveContext,
} from "grommet"

import { Calculator } from "grommet-icons"

import ScreenLayout from "../ScreenLayout"

import { CalculatorContext } from "../../../context/CalculatorContext"

import logo from "../../../images/logo-icon.png"

export default function Screen({ nextStep }) {
  const { currency, setCurrency } = useContext(CalculatorContext)
  const [error, setError] = useState()
  const size = React.useContext(ResponsiveContext)

  const startCalculator = () => {
    if (!currency) setError("Please select your currency")
    else nextStep()
  }

  return (
    <ScreenLayout>
      <Box pad="medium">
        <Box direction="row" justify="between">
          <Heading level="3">The Furnace Cloud Efficiency Calculator</Heading>
          <Box height="48px" width="48px">
            <Image src={logo} />
          </Box>
        </Box>
        <Box pad="medium" gap="medium">
          <Text color="dark-5">
            We've analysed many Cloud estates and deployments to understand what
            makes businesses effective and efficient in the Cloud, and what
            kinds of cost and resource efficiencies can be acheived.
          </Text>
          <Box direction="column" gap="small">
            <Select
              placeholder="Select your Currency"
              options={["USD", "GBP", "EUR"]}
              value={currency}
              onChange={({ option }) => setCurrency(option)}
              alignSelf={size === "small" ? "center" : "start"}
              margin={{ top: "medium" }}
            />
            {error && <Text color="status-critical">{error}</Text>}
          </Box>
          <Button
            primary
            label="Run the calculator"
            onClick={startCalculator}
            alignSelf="center"
            icon={<Calculator />}
            reverse
          />
        </Box>
      </Box>
    </ScreenLayout>
  )
}
