import React, { useContext } from "react"

import { Box, Heading, Text, Form } from "grommet"
import CheckBoxGroup from "../CheckBoxGroup"

import ScreenLayout from "../ScreenLayout"

import inputs from "../data/inputs.json"

export default function Screen({ previousStep, nextStep, goToStep }) {
  const [value, setValue] = React.useState({})

  const items = Object.keys(inputs.resources).map(key => ({
    label: inputs.resources[key],
    value: "resources_" + key,
  }))

  const validate = data => {
    const valid = Object.values(data).length !== 0
    return {
      valid,
      message: !valid && "choose at least one option.",
    }
  }

  return (
    <ScreenLayout
      value={value}
      validate={validate}
      previousStep={previousStep}
      nextStep={nextStep}
      // nextStepHandler={nextStepHandler}
    >
      <Box pad="medium" gap="small">
        <Heading level="3">
          What kind of technical resource do you have in your company?
        </Heading>
        <Text color="dark-5">Choose all that apply.</Text>
        <Box>
          <Form
            value={value}
            onChange={nextValue => {
              setValue(nextValue)
            }}
          >
            <CheckBoxGroup items={items} />
          </Form>
        </Box>
      </Box>
    </ScreenLayout>
  )
}
