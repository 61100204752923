import React from "react"
import { Box, ResponsiveContext } from "grommet"

import Navigation from "./Navigation"

export default function ScreenLayout({
  children,
  previousStep,
  nextStep,
  nextStepHandler,
  value,
  validate,
  process,
  ...props
}) {
  const size = React.useContext(ResponsiveContext)

  let width

  switch (size) {
    case "small":
      width = "100%"
      break
    case "medium":
      width = "100%"
      break
    default:
      width = "xlarge"
  }

  return (
    <Box
      id="screen-container"
      width={width}
      round={{ corner: "bottom", size: "small" }}
      overflow="hidden"
      background="white"
      elevation="small"
      flex
      pad={size}
      {...props}
    >
      <Box>{children}</Box>
      <Navigation
        value={value}
        validate={validate}
        process={process}
        previousStep={previousStep}
        nextStep={nextStep}
        nextStepHandler={nextStepHandler}
      />
    </Box>
  )
}
