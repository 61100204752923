import React from "react"
import styled from "styled-components"
import StepWizard from "react-step-wizard"

import Nav from "../components/calculator/Nav"
import * as Screens from "../components/calculator/screens"

import { CalculatorProvider } from "../context/CalculatorContext"
import { Box, Grommet, ResponsiveContext } from "grommet"

import theme from "../components/calculator/theme"

const Wizard = styled(StepWizard)`
  display: flex;
  flex: 1;
  flex-direction: column;
  /* align-items: center; */
`

export default function Calculator() {
  const size = React.useContext(ResponsiveContext)

  return (
    <Grommet theme={theme} full>
      <Box
        pad={["small", "medium"].includes(size) ? "xsmall" : "xlarge"}
        align="center"
        fill
        background="light-1"
      >
        <CalculatorProvider>
          <Wizard nav={<Nav />}>
            <Screens.Introduction />
            <Screens.Clouds />
            <Screens.AwsServices />
            <Screens.AwsCosts />
            <Screens.CloudCostOther />
            <Screens.TechStack />
            <Screens.TechResource />
            <Screens.ResourceCost />
            <Screens.ShippingSpeed />
            <Screens.Sophistication />
            <Screens.CompanyDetails />
            <Screens.Results />
            <Screens.BookCall />
          </Wizard>
        </CalculatorProvider>
      </Box>
    </Grommet>
  )
}
