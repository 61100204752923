const {
  awsServicesReductionPercentMax,
  resourceReductionPercentMax,
} = require("../components/calculator/data/inputs.json")

const { Decimal } = require("decimal.js")

export default function Calculate(rawData) {
  const data = parseData(rawData)

  const awsBill = calculateAwsBill(data)
  const otherClouds = calculateOtherClouds(data)
  const resourceCosts = calculateResourceCosts(data)
  const shippingIncrease = calculateShippingSpeedIncrease(data)

  const totalCloudSaving = new Decimal(
    awsBill.totalDifference + otherClouds.totalDifference
  ).toDecimalPlaces(2)
  const totalCloudCost = new Decimal(
    awsBill.totalCost + otherClouds.totalCost
  ).toDecimalPlaces(2)
  const totalResourceSaving = new Decimal(
    resourceCosts.totalReducedCost
  ).toDecimalPlaces(2)
  const totalResourceCost = new Decimal(
    resourceCosts.totalCost
  ).toDecimalPlaces(2)

  return {
    awsBill,
    otherClouds,
    resourceCosts,
    shippingIncrease,
    totalCloudSaving,
    totalResourceSaving,
    totalCloudCost,
    totalResourceCost,
  }
}

function calculateShippingSpeedIncrease(data) {
  // const { shippingSpeed, sophistication } = data;
  let shippingSpeed = 10,
    sophistication = 50

  if (sophistication > 90) return 10

  let maxEfficiency = 90 - sophistication

  const increase = ((100 - shippingSpeed) / 100) * maxEfficiency

  return increase
}

function calculateResourceCosts(data) {
  const { resourceCosts, sophistication } = data

  let totalCost = Object.values(resourceCosts).reduce(
    (total, value) => total + value,
    0
  )

  let resources = Object.entries(resourceCosts).map(([resource, cost]) => {
    const reductionPercentMax = resourceReductionPercentMax[resource]
    const reductionPercent =
      (reductionPercentMax / 100) * (100 - sophistication)

    const reducedCost = cost - (cost / 100) * reductionPercent

    return {
      resource,
      cost,
      reducedCost,
      reductionPercentMax,
      reductionPercent,
    }
  })

  const totalReducedCost = resources.reduce(
    (total, { reducedCost }) => total + reducedCost,
    0
  )

  return {
    resources,
    totalCost,
    totalReducedCost,
    totalDifference: totalCost - totalReducedCost,
  }
}

function calculateOtherClouds(data) {
  const { aws, ...cloudCosts } = data.cloudCosts

  let totalCost = Object.values(cloudCosts).reduce(
    (total, value) => total + value,
    0
  )

  const clouds = Object.entries(cloudCosts).map(([key, value]) => ({
    cloud: key,
    total: value,
    reducedCost: (value / 100) * 60,
  }))

  const totalReducedCost = clouds.reduce(
    (total, cloud) => total + cloud.reducedCost,
    0
  )

  return {
    clouds,
    totalCost,
    totalReducedCost,
    totalDifference: totalCost - totalReducedCost,
  }
}

function calculateAwsBill(data) {
  const { costsAws, sophistication } = data

  let totalCost = Object.values(costsAws).reduce(
    (total, value) => total + value,
    0
  )

  let services = Object.entries(costsAws).map(([service, cost]) => {
    const reductionPercentMax = awsServicesReductionPercentMax[service]
    const reductionPercent =
      (reductionPercentMax / 100) * (100 - sophistication)

    const reducedCost = cost - (cost / 100) * reductionPercent

    return {
      service,
      cost,
      reducedCost,
      reductionPercentMax,
      reductionPercent,
    }
  })

  const totalReducedCost = services.reduce(
    (total, { reducedCost }) => total + reducedCost,
    0
  )

  return {
    services,
    totalCost,
    totalReducedCost,
    totalDifference: totalCost - totalReducedCost,
  }
}

function parseData(data) {
  const { shippingSpeed, sophistication } = data

  let parsedData = {
    clouds: getGroup("cloud_", data),
    cloudCosts: getGroup("cloudcost_", data, true),
    servicesAws: getGroup("services_aws_", data),
    costsAws: getGroup("aws_cost_", data, true),
    running: getGroup("running_", data),
    resources: getGroup("resources_", data),
    resourceCosts: getGroup("resourcecost_", data, true),
    shippingSpeed,
    sophistication,
  }

  return parsedData
}

function getGroup(prefix, data, isNumber) {
  let obj = {}

  Object.entries(data)
    .filter(([key]) => key.startsWith(prefix))
    .forEach(
      ([key, value]) =>
        (obj[key.replace(prefix, "")] = isNumber ? parseInt(value) : value)
    )

  return obj
}
