import React from "react"
import { Box, CheckBox, Text } from "grommet"
import { HelpIcon } from "./atoms"

export default function CheckBoxGroup({ direction, wrap, items }) {
  return (
    <Box direction={direction} wrap={wrap}>
      {items.map((item, index) => {
        let label
        if (item.help) {
          label = (
            <Box direction="row" gap="small" align="center" justify="start">
              <Text>{item.label}</Text>
              <HelpIcon help={item.help}>i</HelpIcon>
            </Box>
          )
        } else label = item.label

        return (
          <Box key={index} pad="small">
            <CheckBox name={item.value} label={label} />
          </Box>
        )
      })}
    </Box>
  )
}

CheckBoxGroup.defaultProps = {
  direction: "column",
  wrap: false,
}
