import React, { useContext, useEffect, useState } from "react"

import { Box, Heading, Text, Form, FormField, TextInput } from "grommet"
import ScreenLayout from "../ScreenLayout"

import { CalculatorContext } from "../../../context/CalculatorContext"

import inputs from "../data/inputs.json"

const prefix = "cloud_"
const costPrefix = "cloudcost_"

export default function Screen({ previousStep, nextStep }) {
  const { data, getCurrencySymbol } = useContext(CalculatorContext)
  const [value, setValue] = useState({})

  useEffect(() => {
    let newValue = {}

    const awsServicesCost = Object.entries(data)
      .filter(([key, value]) => key.startsWith("aws_cost_"))
      .reduce((total, [key, value]) => total + parseInt(value), 0)

    for (let v of Object.keys(inputs.clouds)) {
      if (data[prefix + v]) {
        newValue = { ...value, [costPrefix + v]: "" }
      }
    }

    if (awsServicesCost > 0) {
      newValue["cloudcost_aws"] = awsServicesCost.toString()
    }

    setValue(newValue)
  }, [data])

  const list = Object.entries(data)
    .filter(([key, value]) => key.startsWith(prefix))
    .filter(([key, value]) => value === true)
    .map(([key, value]) => key.replace(prefix, ""))

  const validate = data => {
    let valid = true

    for (let k of Object.keys(data)) {
      if (!data[k] || isNaN(data[k]) || data[k] === 0) {
        valid = false
        break
      }
    }

    return {
      valid,
      message: !valid && "cost must be greater than 0 for all resources",
    }
  }

  return (
    <ScreenLayout
      id="cloud-costs-all"
      value={value}
      validate={validate}
      previousStep={previousStep}
      nextStep={nextStep}
    >
      <Box pad="medium" gap="small">
        <Heading level="3">
          What is the current spend across each of your Cloud platforms
        </Heading>
        <Text color="dark-5">
          Provide the combined cost per cloud, per month.
        </Text>
        <Box pad="medium">
          <Form
            value={value}
            onChange={nextValue => {
              setValue(nextValue)
            }}
          >
            {list.map(r => (
              <FormField key={r} label={inputs.clouds[r]} width="small">
                <TextInput
                  name={costPrefix + r}
                  placeholder={getCurrencySymbol()}
                />
              </FormField>
            ))}
          </Form>
        </Box>
      </Box>
    </ScreenLayout>
  )
}
