import React from "react"
import { Box } from "grommet"

export default function Nav({ currentStep, totalSteps }) {
  const percent = Math.round((100 / totalSteps) * currentStep)
  const width = percent.toString() + "%"
  return (
    <Box
      border={{ color: "light-1", side: "bottom", size: "1px", style: "solid" }}
      height="4px"
    >
      <Box width={width} height="4px" background="brand">
        &nbsp;
      </Box>
    </Box>
  )
}
