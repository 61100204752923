import React from "react"

import {
  Box,
  Heading,
  Form,
  RangeInput,
  Text,
  ResponsiveContext,
} from "grommet"
import ScreenLayout from "../ScreenLayout"

export default function Screen({ previousStep, nextStep }) {
  const [value, setValue] = React.useState({ sophistication: 50 })
  const size = React.useContext(ResponsiveContext)

  return (
    <ScreenLayout value={value} previousStep={previousStep} nextStep={nextStep}>
      <Box pad="medium" gap="small">
        <Heading level="3">
          How sophisticated is your business when it comes to technology?
        </Heading>
        <Text color="dark-5">
          Tell us to what extent your company uses technology to operate and
          provide a competitive advantage.
        </Text>
        <Text color="dark-5">
          For some businesses, technology is at the core of everything they do,
          for others, it is used to simply support the main business endeavour.
        </Text>
        <Text color="dark-5">
          More sophisticated businesses have development teams, are data-driven
          in everything they do, and make use of many platforms, services and
          tools to continuously deliver value.
        </Text>
        <Box pad="medium">
          <Form
            value={value}
            onChange={nextValue => {
              setValue(nextValue)
            }}
          >
            <Box direction="row" justify="between">
              <Text color="dark-5" size={size} textAlign="start">
                Lacking
              </Text>
              <Text color="dark-5" size={size} textAlign="center">
                Adequate
              </Text>
              <Text color="dark-5" size={size} textAlign="end">
                Pushing boundaries
              </Text>
            </Box>
            <RangeInput
              value={value.sophistication}
              onChange={event =>
                setValue({ ...value, sophistication: event.target.value })
              }
            />
          </Form>
        </Box>
      </Box>
    </ScreenLayout>
  )
}
