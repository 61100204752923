import React, { useState } from "react";
import { Box, Text, Layer, ResponsiveContext, Button } from "grommet";

export default function HelpIcon({ background, color, help, children }) {
  const [showHelp, setShowHelp] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const size = React.useContext(ResponsiveContext);

  return (
    <Box direction="row" gap="small">
      <Box
        background={background}
        pad="3px"
        round="full"
        width={{ min: "24px" }}
        justify="center"
        align="center"
        onMouseEnter={() => {
          if (size !== "small") setShowHelp(true);
        }}
        onMouseLeave={() => {
          if (size !== "small") setShowHelp(false);
        }}
        onClick={() => {
          if (size === "small") setShowPopup(true);
        }}
      >
        <Text color={color} size="xsmall">
          {children}
        </Text>
      </Box>
      {showHelp && (
        <Box>
          <Text size="small">{help}</Text>
        </Box>
      )}
      {showPopup && (
        <Layer
          onEsc={() => setShowPopup(false)}
          onClickOutside={() => setShowPopup(false)}
          onClick={() => setShowPopup(false)}
          full
        >
          <Box flex justify="center" align="center" gap="medium">
            <Text>{help}</Text>
            <Button label="Close Help" onClick={() => setShowPopup(false)} />
          </Box>
        </Layer>
      )}
    </Box>
  );
}

HelpIcon.defaultProps = {
  background: "light-5",
  color: "white",
};
