import React from "react"

import { Box, Heading, Text, Form } from "grommet"

import ScreenLayout from "../ScreenLayout"
import CheckBoxGroup from "../CheckBoxGroup"

import inputs from "../data/inputs.json"

export default function Screen({ previousStep, nextStep }) {
  const [value, setValue] = React.useState({})

  const items = Object.keys(inputs.awsServices).map(key => ({
    label: inputs.awsServices[key],
    value: "services_aws_" + key,
  }))

  const validate = data => {
    const valid = Object.values(data).length !== 0
    return {
      valid,
      message: !valid && "choose at least one option.",
    }
  }

  return (
    <ScreenLayout
      value={value}
      validate={validate}
      previousStep={previousStep}
      nextStep={nextStep}
    >
      <Box pad="medium" gap="small">
        <Heading level="3">
          Which AWS Services do you currently make use of?
        </Heading>
        <Text color="dark-5">Choose all that apply.</Text>
        <Box>
          <Form
            value={value}
            onChange={nextValue => {
              setValue(nextValue)
            }}
          >
            <CheckBoxGroup
              direction="row-responsive"
              wrap={true}
              items={items}
            />
          </Form>
        </Box>
      </Box>
    </ScreenLayout>
  )
}
