import React from "react"

import {
  Box,
  Heading,
  Form,
  RangeInput,
  Text,
  ResponsiveContext,
  Anchor,
} from "grommet"
import ScreenLayout from "../ScreenLayout"

export default function Screen({ previousStep, nextStep }) {
  const [value, setValue] = React.useState({ shippingSpeed: 50 })
  const size = React.useContext(ResponsiveContext)

  return (
    <ScreenLayout value={value} previousStep={previousStep} nextStep={nextStep}>
      <Box pad="medium" gap="small">
        <Heading level="3">
          Rate your current of shipping and innovation.
        </Heading>
        <Text color="dark-5">
          Organisations that ship faster have a significant business advantage.
        </Text>
        <Text color="dark-5">
          In the 2019 State of DevOps Report, "elite performing teams" were
          found to ship 100's of times faster than low performers, using a
          combination of software, processes and Cloud platforms.
        </Text>
        <Text color="dark-5">
          For context, CapitalOne ship around 50 times per day, Amazon, Google,
          Netflix are in the thousands.
        </Text>
        <Text size="xsmall">
          Source -{" "}
          <Anchor
            href="https://puppet.com/resources/report/state-of-devops-report/"
            target="_blank"
          >
            2019 State of DevOps Report
          </Anchor>
        </Text>
        <Box pad="medium">
          <Form
            value={value}
            onChange={nextValue => {
              setValue(nextValue)
            }}
          >
            <Box direction="row" justify="between" gap="small">
              <Text color="dark-5" size={size} textAlign="start">
                Needs to be better
              </Text>
              <Text color="dark-5" size={size} textAlign="center">
                Adequate
              </Text>
              <Text color="dark-5" size={size} textAlign="end">
                We ship at pace
              </Text>
            </Box>
            <RangeInput
              value={value.shippingSpeed}
              onChange={event =>
                setValue({ ...value, shippingSpeed: event.target.value })
              }
            />
          </Form>
        </Box>
      </Box>
    </ScreenLayout>
  )
}
