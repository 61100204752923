import React, { useContext } from "react"

import { Box, Heading, Form, TextInput, Select } from "grommet"
import ScreenLayout from "../ScreenLayout"

import { postLead } from "../../../services/furnace-marketing"
import { CalculatorContext } from "../../../context/CalculatorContext"

export default function Screen({ previousStep, nextStep }) {
  const { data } = useContext(CalculatorContext)
  const [value, setValue] = React.useState({
    Name: "",
    ["Company Name"]: "",
    Email: "",
    ["Company Size"]: "",
    Sector: "",
  })

  const validate = form => {
    const valid =
      form.Name &&
      form["Company Name"] &&
      form.Email &&
      form["Company Size"] &&
      form.Sector

    return {
      valid,
      message: !valid && "please complete all fields",
    }
  }

  const process = async form => {
    const response = await postLead({
      ...form,
      Data: JSON.stringify(data),
    })

    return response.success
  }

  return (
    <ScreenLayout
      value={value}
      validate={validate}
      previousStep={previousStep}
      nextStep={nextStep}
      process={process}
    >
      <Box pad="medium">
        <Heading level="3">Tell us more about you.</Heading>
        {/* <Text color="dark-5">
          Tell us to what extent your company uses technology to operate and
          provide competitive advantage.
        </Text> */}

        <Form
          value={value}
          onChange={nextValue => {
            setValue(nextValue)
          }}
        >
          <Box direction="row">
            <Box basis="1/2" gap="small" pad="medium">
              <TextInput name="Name" placeholder="Your Name" />
              <TextInput name="Company Name" placeholder="Company Name" />
              <TextInput
                name="Email"
                type="email"
                placeholder="Email Address"
              />
            </Box>
            <Box basis="1/2" gap="small" pad="medium">
              <Select
                placeholder="Company Size"
                name="Company Size"
                options={["1-10", "11-50", "51-100", "101-500", "501+"]}
              />
              <TextInput placeholder="Sector" name="Sector" />
            </Box>
          </Box>
        </Form>
      </Box>
    </ScreenLayout>
  )
}
