import React, { useContext, useEffect, useState } from "react"

import {
  Box,
  Heading,
  Text,
  Accordion,
  AccordionPanel,
  Button,
  ResponsiveContext,
} from "grommet"

import ScreenLayout from "../ScreenLayout"

import { CalculatorContext } from "../../../context/CalculatorContext"
import LabelledValue from "../LabelledValue"

export default function Screen({ isActive, previousStep, nextStep }) {
  const { data, calculate, getCurrencySymbol } = useContext(CalculatorContext)
  const [result, setResult] = useState()
  const size = React.useContext(ResponsiveContext)

  useEffect(() => {
    if (isActive) {
      const calc = calculate(data)
      setResult(calc)
    }
  }, [isActive])

  const currencySymbol = getCurrencySymbol()

  return (
    <ScreenLayout>
      <Box pad="medium" gap="small">
        <Heading level="3">You made it!</Heading>
        <Text color="dark-5">
          Based on the information you provided us, we have calculated how much
          you could save on a monthly based and by what factor you can increase
          your shipping speed.
        </Text>
        {result && (
          <Box>
            <Box gap="medium" margin={{ top: "medium" }}>
              <LabelledValue
                label="Infra Cost Savings"
                border={size === "small" ? "1px" : { side: "bottom" }}
                metric={`per month, from total of ${currencySymbol}${result.totalCloudCost.toString()}`}
              >
                {currencySymbol} {result.totalCloudSaving.toString()}
              </LabelledValue>
              <LabelledValue
                label="Technical Resource Savings"
                border={size === "small" ? "1px" : { side: "bottom" }}
                metric={`per month, from total of ${currencySymbol}${result.totalResourceCost.toString()}`}
              >
                {currencySymbol} {result.totalResourceCost.toString()}
              </LabelledValue>
              <LabelledValue
                label="Shipping Speed Increase"
                border={size === "small" ? "1px" : false}
              >
                {result.shippingIncrease}%
              </LabelledValue>
              <Box align="center">
                <Button
                  primary
                  label="Book Free Consultation Now"
                  onClick={nextStep}
                  style={{ maxWidth: "200px" }}
                />
              </Box>
            </Box>
            {/* <Box margin="large" gap="medium">
              <Accordion>
                <AccordionPanel label="Show Calculations">
                  <Box pad="medium" background="light-1">
                    <pre>{JSON.stringify(result, null, "\t")}</pre>
                  </Box>
                </AccordionPanel>
              </Accordion>
            </Box> */}
          </Box>
        )}
      </Box>
    </ScreenLayout>
  )
}
