import React from "react"

import { Box, Heading, Text, Form } from "grommet"
import ScreenLayout from "../ScreenLayout"

import CheckBoxGroup from "../CheckBoxGroup"

export default function Screen({ previousStep, nextStep }) {
  const [value, setValue] = React.useState({})

  const items = [
    {
      label: "Public facing website",
      value: "running_website",
    },
    {
      label: "API's",
      value: "running_api",
      help: "Published API's to external consumers",
    },
    {
      label: "E-Commerce platform",
      value: "running_ecom",
    },
    {
      label: "SasS Apps",
      value: "running_saas_apps",
      help: "You're a SaaS app developer/provider",
    },
    {
      label: "Line of business Apps",
      value: "running_lob",
      help: "Off the shelf of custom apps that your business operates",
    },
    {
      label: "Containers/microservices",
      value: "running_microservices",
      help: "Running on things like Docker/Kubernetes",
    },
    {
      label: "Serverless services",
      value: "running_services",
      help:
        "Serverless Functions such as Lambda or other Cloud native services",
    },
    {
      label: "Streaming data pipelines",
      value: "running_streaming",
      help: "Continuous streams of data from internal or external sources",
    },
    {
      label: "ETL Jobs",
      value: "running_etl",
      help: "Extract/Transform/Load, Big Data type workloads",
    },
    {
      label: "SasS Integrations",
      value: "running_saas_int",
      help: "Connect SaaS applications to one another",
    },
  ]

  const validate = data => {
    const valid = Object.values(data).length !== 0
    return {
      valid,
      message: !valid && "choose at least one option.",
    }
  }

  return (
    <ScreenLayout
      value={value}
      validate={validate}
      previousStep={previousStep}
      nextStep={nextStep}
    >
      <Box pad="medium" gap="small">
        <Heading level="3">
          What kinds of applications and services are you running on Cloud?
        </Heading>
        <Text color="dark-5">Choose all that apply.</Text>
        <Box>
          <Form
            value={value}
            onChange={nextValue => {
              setValue(nextValue)
            }}
          >
            <CheckBoxGroup items={items} />
          </Form>
        </Box>
      </Box>
    </ScreenLayout>
  )
}
